import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Page from "../components/page";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";

export default class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			login: false,
			passwordValidator: [false, false, false, false],
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const parent = this;
		const loanSize = queryString.parse(this.props.location.search).s;
		const uvc = queryString.parse(this.props.location.search).u;
		const mileage = queryString.parse(this.props.location.search).m;
		const signup = queryString.parse(this.props.location.search)["signup"];
		const store = queryString.parse(this.props.location.search)["st"];
		const token = queryString.parse(this.props.location.search)["token"];
		if (this.props.uid) {
			parent.props.history.push("/status");
		}
		if (store) {
			parent.props.onUpdateStore(parseInt(store));
		}
		this.setState({
			loanSize: loanSize ? JSON.parse(atob(loanSize)) : 0,
			uvc: uvc,
			mileage: mileage,
			login: !signup,
		});
		if (token) {
			return this._signInWithToken(token);
		}
		return true;
	}

	_toggle = () => {
		this.setState({
			login: !this.state.login,
		});
	};

	_facebook = () => {
		const provider = new firebase.auth.FacebookAuthProvider();
		firebase.auth().languageCode = this.props.lang;

		firebase
			.auth()
			.signInWithPopup(provider)
			.then(user => this._createSocialUser(user, "facebook"))
			.catch(error => this.throwError(error));
	};

	_google = () => {
		const provider = new firebase.auth.GoogleAuthProvider();
		firebase.auth().languageCode = this.props.lang;

		firebase
			.auth()
			.signInWithPopup(provider)
			.then(user => this._createSocialUser(user, "google"))
			.catch(error => this.throwError(error));
	};

	throwError = e => {
		return Swal.fire("Something went Wrong", e.message, "error");
	};

	_createUser = async e => {
		const parent = this;
		e.preventDefault();
		if (this.state.passwordValidator.filter(e => e === false).length > 0) {
			return Swal.fire(this.props.messages.commText.oops, this.props.messages.login.textN01, "warning");
		} else if (this.state.password !== this.state.passwordConfirm) {
			return Swal.fire(this.props.messages.commText.oops, this.props.messages.login.textN02, "warning");
		} else if (this.state.military) {
			return Swal.fire(this.props.messages.login.textN03, this.props.messages.login.textN04, "warning");
		} else {
			try {
				const referrerID = parent.props.referral?.referrerID ?? null;
				const referralSource = parent.props.referral?.source ?? parent.props.referral?.split("/")[2] ?? null;

				const user = await firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password);
				await firebase.auth().currentUser.updateProfile({
					displayName: parent.state.firstName + " " + parent.state.lastName,
				});
				await firebase
					.firestore()
					.doc("admin/" + user.user.uid)
					.set({
						timestamp: moment().toDate(),
						skipCreditCheck: true,
					});
				await firebase
					.firestore()
					.doc("applications/" + user.user.uid)
					.set({
						status: 0,
						store: 0,
						active: 1,
						using: parent.state.uvc ? "uvc" : null,
						uvc: parent.state.uvc ? JSON.parse(parent.state.uvc) : null,
						mileage: parent.state.mileage ? JSON.parse(parent.state.mileage) : null,
						// phoneAge: parent.state.phoneAge,
						source: "Website",
						loanSize: parent.state.loanSize,
						timestamp: moment().toDate(),
					});
				await firebase
					.firestore()
					.doc("users/" + user.user.uid)
					.set({
						uid: user.user.uid,
						firstName: parent.state.firstName,
						lastName: parent.state.lastName,
						email: parent.state.email,
						phone: parent.state.phone,
						// phoneAge: parent.state.phoneAge,
						referral: referrerID ?? referralSource,
						timestamp: moment().toDate(),
					});
				parent._saveReferral(parent.state.firstName, parent.state.lastName, parent.state.phone);
				window.gtag("event", "conversion", {
					send_to: "AW-974126037/WWKhCMCYq6gBENX3v9AD",
				});
				window.gtag("event", "conversion", {
					send_to: "AW-761506876/0dVwCJbQvawBELzYjusC",
				});
				return parent.props.history.push({
					pathname: "/application",
					search: "?c=true",
				});
			} catch (err) {
				return await Swal.fire(parent.props.messages.commText.oops, err.message, "warning");
			}
		}
	};

	_createSocialUser = async (user, source) => {
		const parent = this;
		if (!user.additionalUserInfo.isNewUser) {
			return setTimeout(() => {
				return parent.props.history.push("/status");
			}, 1600);
		}

		const referrerID = parent.props.referral?.referrerID ?? null;
		const referralSource = parent.props.referral?.source ?? parent.props.referral?.split("/")?.[2] ?? null;

		await firebase
			.firestore()
			.doc("admin/" + user.user.uid)
			.set({
				timestamp: moment().toDate(),
				skipCreditCheck: true,
			});
		await firebase
			.firestore()
			.doc("applications/" + user.user.uid)
			.set({
				status: 0,
				store: 0,
				active: 1,
				using: this.state.uvc ? "uvc" : null,
				uvc: this.state.uvc ? JSON.parse(this.state.uvc) : null,
				mileage: this.state.mileage ? JSON.parse(this.state.mileage) : null,
				// phoneAge: this.state.phoneAge,
				source: "Website",
				loanSize: this.state.loanSize,
				timestamp: moment().toDate(),
			});
		return firebase
			.firestore()
			.doc("users/" + user.user.uid)
			.set({
				uid: user.user.uid,
				firstName: source === "facebook" ? user.additionalUserInfo?.profile?.["first_name"] : user.additionalUserInfo?.profile?.["given_name"],
				lastName: source === "facebook" ? user.additionalUserInfo?.profile?.["last_name"] : user.additionalUserInfo?.profile?.["family_name"],
				email: user.user.email,
				// phone: this.state.phone,
				// phoneAge: this.state.phoneAge,
				referral: referrerID ?? referralSource ?? null,
				timestamp: moment().toDate(),
			})
			.then(() => {
				parent._saveReferral(
					source === "facebook" ? user.additionalUserInfo.profile["first_name"] : user.additionalUserInfo.profile["given_name"],
					source === "facebook" ? user.additionalUserInfo.profile["last_name"] : user.additionalUserInfo.profile["family_name"]
				);
				window.gtag("event", "conversion", {
					send_to: "AW-974126037/WWKhCMCYq6gBENX3v9AD",
				});
				window.gtag("event", "conversion", {
					send_to: "AW-761506876/0dVwCJbQvawBELzYjusC",
				});
				return parent.props.history.push({
					pathname: "/application",
					search: "?c=true",
				});
			});
	};

	_signIn = e => {
		const parent = this;
		firebase
			.auth()
			.signInWithEmailAndPassword(this.state.email, this.state.password)
			.then(() => {
				return setTimeout(() => {
					return parent.props.history.push("/status");
				}, 1600);
			})
			.catch(error => {
				return Swal.fire(parent.props.messages.commText.oops, error.message, "warning");
			});
		e.preventDefault();
	};

	_signInWithToken = token => {
		const parent = this;
		return firebase
			.auth()
			.signInWithCustomToken(token)
			.then(() => {
				return setTimeout(() => {
					return parent.props.history.push("/status");
				}, 1600);
			});
	};

	_validatePassword = e => {
		const password = e.target.value;
		const number = /[0-9]/.test(password);
		const lowercase = /[a-z]/.test(password);
		const uppercase = /[A-Z]/.test(password);

		this.setState({
			passwordValidator: [password.length > 7, number, lowercase, uppercase],
			password: password,
		});
	};

	_forgotPassword = async () => {
		const parent = this;
		const res = Swal.fire({
			title: `<h4>${this.props.messages.login.textN05}</h4>`,
			input: "email",
			inputAttributes: {
				autocapitalize: "off",
				placeholder: this.props.messages.login.textN06,
			},
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: this.props.messages.login.textN31,
		});
		if (res.value) {
			return firebase
				.auth()
				.sendPasswordResetEmail(res.value)
				.then(() => {
					return Swal.fire(parent.props.messages.commText.salvado, parent.props.messages.login.textN07, "success");
				})
				.catch(error => {
					return Swal.fire(parent.props.messages.commText.oops, error.message, "warning");
				});
		}
		return null;
	};

	_saveReferral = async (firstName, lastName, phone) => {
		if (this.props.referral) {
			if (this.props.referral.source === "C2B") {
				if (this.props.referral.referralUUID) {
					const linkReferral = firebase.functions().httpsCallable("linkReferralFromApp");
					return linkReferral({
						referrer: this.props.referral.referrerID,
						referral: this.props.referral.referralUUID,
					});
				} else {
					const addReferral = firebase.functions().httpsCallable("addReferralFromApp");
					const addReferralFunc = await addReferral({
						uid: this.props.referral.referrerID,
						newUser: this.props.uid,
						name: firstName + " " + lastName,
						phone: phone ?? null,
					});
					return firebase
						.firestore()
						.doc(`users/${this.props.uid}`)
						.update({
							referralRef: firebase.firestore().doc(`title-referrals/${addReferralFunc.data}`),
						});
				}
			}
			if (this.props.referral.startsWith("AUTO")) {
				const referralID = this.props.referral.split("/")[1];
				const applicationID = this.props.referral.split("/")[2];

				return axios.post("https://us-central1-dtl-prism.cloudfunctions.net/autoAddReferral", null, {
					params: {
						referralID,
						applicationID,
						firstName,
						lastName,
					},
				});
			}
		}
		return null;
	};

	render() {
		return (
			<Page {...this.props}>
				<div className="container mt-3 py-3">
					<div className="page-title__holder text-center">
						{this.state.login ? (
							<div>
								<h1 className="page-title__title">{this.props.messages.login.textN08}</h1>
								<p className="page-title__subtitle">
									{this.props.messages.login.textN09}{" "}
									<span className="link" onClick={this._toggle}>
										{this.props.messages.login.textN10}
									</span>
								</p>
							</div>
						) : (
							<div>
								<h1 className="page-title__title">{this.props.messages.login.textN11}</h1>
								<p className="mb-2 page-title__subtitle">
									{this.props.messages.login.textN12}{" "}
									<span className="link" onClick={this._toggle}>
										{this.props.messages.login.textN13}
									</span>
								</p>
							</div>
						)}
						<br />
					</div>
					{this.state.login ? (
						<form className="contact-form login mt-30 mb-30 mx-auto text-center" onSubmit={this._signIn}>
							<div className="cool-form-group row align-items-center">
								<span className="col-4">{this.props.messages.login.textN14}</span>
								<input type="email" className="col-8" required onChange={e => this.setState({ email: e.target.value })} />
							</div>
							<div className="cool-form-group row align-items-center">
								<span className="col-4">{this.props.messages.login.textN15}</span>
								<input
									type="password"
									className="col-8"
									required
									onChange={e =>
										this.setState({
											password: e.target.value,
										})
									}
								/>
							</div>
							<Link to={"/reset-password"}>
								<span className="link">{this.props.messages.login.textN16}</span>
							</Link>
							<br />
							<br />
							<button
								type={"submit"}
								style={{
									marginBottom: 10,
									padding: 10,
									minWidth: 300,
								}}
								className="btn btn--color btn--button">
								<i
									style={{
										marginLeft: 8,
										marginRight: 20,
										fontSize: 18,
									}}
									className={"fas fa-lock-open-alt"}
								/>
								{this.props.messages.login.textN33}
							</button>
							<br />
							<button
								type={"button"}
								style={{
									marginBottom: 10,
									padding: 10,
									minWidth: 300,
									backgroundColor: "#4267B2",
								}}
								onClick={this._facebook}
								className="btn btn--color btn--button">
								<i
									style={{
										marginLeft: 8,
										marginRight: 20,
										fontSize: 18,
									}}
									className={"fab fa-facebook-square"}
								/>
								{this.props.messages.login.textN34}
							</button>
							<br />
							<button
								type={"button"}
								style={{
									marginBottom: 10,
									padding: 10,
									minWidth: 300,
									backgroundColor: "#DB4336",
								}}
								onClick={this._google}
								className="btn btn--color btn--button">
								<i
									style={{
										marginLeft: 8,
										marginRight: 20,
										fontSize: 18,
									}}
									className={"fab fa-google"}
								/>
								{this.props.messages.login.textN35}
							</button>
						</form>
					) : (
						<form className="container contact-form mt-30 mb-30 mx-auto text-center" onSubmit={this._createUser}>
							<div className="row">
								<div className="col-lg-6 cool-form-group row align-items-center">
									<span className="col-4">{this.props.messages.login.textN17}</span>
									<input
										type="text"
										className="col-8"
										required
										onChange={e =>
											this.setState({
												firstName: e.target.value,
											})
										}
									/>
								</div>
								<div className="col-lg-6 cool-form-group row align-items-center">
									<span className="col-4">{this.props.messages.login.textN18}</span>
									<input
										type="text"
										className="col-8"
										required
										onChange={e =>
											this.setState({
												lastName: e.target.value,
											})
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6 cool-form-group row align-items-center">
									<span className="col-4">{this.props.messages.login.textN19}</span>
									<input
										type="text"
										className="col-8"
										required
										onChange={e =>
											this.setState({
												phone: e.target.value,
											})
										}
									/>
								</div>
								{/*<div className="col-lg-4 cool-form-group row align-items-center">
									<span className="col-6">How long have you had this number?</span>
									<select className="col-6" required defaultValue="" onChange={e => this.setState({phoneAge : JSON.parse(e.target.value)})}>
										<option value="" disabled></option>
										<option value="0">Less than 2 years</option>
										<option value="2">More than 2 years</option>
									</select>
								</div>*/}
								<div className="col-lg-6 cool-form-group row align-items-center">
									<span className="col-8">{this.props.messages.login.textN20}</span>
									<select
										className="col-4"
										required
										defaultValue=""
										onChange={e =>
											this.setState({
												military: JSON.parse(e.target.value),
											})
										}>
										<option value="" disabled />
										<option value="true">{this.props.messages.login.textN21}</option>
										<option value="false">{this.props.messages.login.textN22}</option>
									</select>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 cool-form-group row align-items-center">
									<span className="col-6">{this.props.messages.login.textN23}</span>
									<input
										type="email"
										className="col-6"
										required
										onChange={e =>
											this.setState({
												email: e.target.value,
											})
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6 cool-form-group row align-items-center">
									<span className="col-4">{this.props.messages.login.textN24}</span>
									<input type="password" className="col-8" onChange={this._validatePassword} required />
								</div>
								<div className="col-lg-6 cool-form-group row align-items-center">
									<span className="col-4">{this.props.messages.login.textN25}</span>
									<input
										type="password"
										className="col-8"
										required
										onChange={e =>
											this.setState({
												passwordConfirm: e.target.value,
											})
										}
									/>
								</div>
							</div>
							<br />
							<div className="col-12 row justify-content-md-center text-left no-gutters px-0">
								<div className="col-xs-12 col-md-6 col-lg-4 row no-gutters">
									<div className="col-6">
										<div className="form-check">
											<input readOnly className="form-check-input" checked={this.state.passwordValidator[0]} type="checkbox" id="req0" />
											<label className="form-check-label" htmlFor="req0">
												{this.props.messages.login.textN26}
											</label>
										</div>
									</div>
									<div className="col-6">
										<div className="form-check">
											<input readOnly className="form-check-input" checked={this.state.passwordValidator[2]} type="checkbox" id="req2" />
											<label className="form-check-label" htmlFor="req2">
												{this.props.messages.login.textN27}
											</label>
										</div>
									</div>
								</div>
								<div className="col-xs-12 col-md-6 col-lg-4 row no-gutters">
									<div className="col-6">
										<div className="form-check">
											<input readOnly className="form-check-input" checked={this.state.passwordValidator[3]} type="checkbox" id="req3" />
											<label className="form-check-label" htmlFor="req3">
												{this.props.messages.login.textN28}
											</label>
										</div>
									</div>
									<div className="col-6">
										<div className="form-check">
											<input readOnly className="form-check-input" checked={this.state.passwordValidator[1]} type="checkbox" id="req1" />
											<label className="form-check-label" htmlFor="req1">
												{this.props.messages.login.textN29}
											</label>
										</div>
									</div>
								</div>
							</div>
							<br />
							<br />
							<input type="submit" className="btn btn--color btn--button" value={this.props.messages.login.textN32} />
							<p className="mt-3" style={{ color: "#ccc" }}>
								{process.env.REACT_APP_TYPE === "all" ? this.props.messages.login.textN30 : null}
								{process.env.REACT_APP_TYPE === "title" ? this.props.messages.login.textN36 : null}
								{process.env.REACT_APP_TYPE === "finance" ? this.props.messages.login.textN37 : null}
							</p>
						</form>
					)}
				</div>
			</Page>
		);
	}
}
